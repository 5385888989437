<template>
  <div class="co-flex-col">
    <v-card
      class="rounded overflow-y-auto overflow-x-hidden"
      flat
      tile
      color="white"
      height="640"
    >
      <div class="co-flex-col co-justify-between co-h-full py-6 px-10">
        <template v-if="items.length">
          <div class="co-flex-col">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="co-flex-col"
            >
              <div class="co-flex-row co-items-center">
                <v-avatar
                  size="64"
                  style="cursor: pointer;"
                  @click="toHomepage(index, item)"
                >
                  <v-img
                    :src="item.fansAvatar"
                    cover
                  />
                </v-avatar>
                <div class="co-flex-row co-justify-between co-items-center co-flex-grow ml-4">
                  <div class="co-flex-col">
                    <span
                      class="primary--text"
                      style="cursor: pointer;"
                      @click="toHomepage(index, item)"
                    >
                      {{ item.fansUsername }}
                      <span class="co-text-gray-700 ml-4">{{ hint.label }}</span>
                    </span>
                    <span class="co-text-xs co-text-gray-500 mt-2">
                      {{ hint.fanLabel }}
                      <span class="ml-2">{{ item.fansFansNum || 0 }}</span>
                      <span
                        class="primary--text ml-4"
                        style="cursor: pointer;"
                        @click="toReply(item)"
                      >私信</span>
                    </span>
                  </div>

                  <template v-if="item.mutualAttentionFlag === 'yes'">
                    <v-btn
                      class="rounded white--text"
                      tile
                      :color="btnTog.color"
                      width="120"
                    >
                      <v-icon
                        left
                        dark
                      >
                        {{ btnTog.icon }}
                      </v-icon>
                      {{ btnTog.text }}
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      class="rounded"
                      tile
                      outlined
                      :color="btnAtt.color"
                      width="120"
                      @click="onAttention(item)"
                    >
                      <v-icon left>
                        {{ btnAtt.icon }}
                      </v-icon>
                      {{ btnAtt.text }}
                    </v-btn>
                  </template>
                </div>
              </div>
              <div class="co-w-full co-h2 co-border-t co-border-gray-300 my-4" />
            </div>
          </div>
        </template>
        <template v-else>
          <span class="co-text-sm co-text-gray-500">
            {{ hint.empty }}
          </span>
        </template>
      </div>
    </v-card>

    <div class="d-flex flex-row my-4">
      <v-pagination
        v-model="navPage.current"
        :length="navPage.length"
        :total-visible="navPage.totalVisible"
        @input="changePagination($event, catCur.name)"
      />
    </div>

    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'

  export default {
    name: 'SectionMessageLikes',
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      pagination: {
        type: Object,
        default: () => {
          return {
            current: 1,
            length: 0,
            totalVisible: 7
          }
        }
      }
    },
    data () {
      return {
        currUser: {},
        hint: {
          label: '关注了您',
          empty: '目前没有粉丝',
          fanLabel: '粉丝数'
        },
        btnAtt: {
          icon: 'mdi-plus',
          text: '关注',
          color: 'primary'
        },
        btnTog: {
          icon: 'mdi-swap-horizontal',
          text: '相互关注',
          color: 'blue-grey'
        },
        navPage: {
          current: 1,
          length: 0,
          totalVisible: 7
        }
      }
    },
    watch: {
      pagination: {
        handler (val) {
          this.navPage = Object.assign({}, val)
        },
        immediate: true
      }
    },
    created () {
      this.currUser = user.info.get()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      onAttention (item) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            item.mutualAttentionFlag = 'yes'
            me.showAlert('success', '加关注成功')
          } else {
            const err = res.data || {}
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        web.behaviour.attention({
          attentionUserId: item.fansUserId,
          executed
        })
      },
      toReply (item) {
        this.$emit('reply', {
          userId: item.fansUserId,
          username: item.fansUsername,
          avatar: item.fansAvatar
        })
      },
      toHomepage (index, item) {
        // console.log({ item })
        const userId = item.fansUserId
        if (!userId) {
          return
        }

        if (userId === this.currUser.userId) {
          const params = {
            ...this.currUser
          }
          api.page.navigate(this, 'my', params)
        } else {
          api.page.navigateBy(this, 'homepage', {
            userId
          })
        }
      },
    }
  }
</script>
